import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists } from '@administration/codelists/codelists.service';
import { CodeList } from '@common/classes/codelist';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { FileRestrictions } from '@progress/kendo-angular-upload';

export enum InputType {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    BOOLEAN = 'boolean',
    DATERANGE = 'daterange',
    DATETIME = 'datetime',
    DATE = 'date',
    NUMBER = 'number',
    PASSWORD = 'password',
    CODELIST = 'codelist',
    STATIC = 'static',
    SELECT = 'select',
    FILE = 'file',
    YESNO = 'yesno',
    COLOR = 'color',
    EDITOR = 'editor',
    EMAIL = 'email'
}

export type LabelPosition = 'top' | 'left' | 'right';

export type BaseInput = {
    label?: string;
    labelPosition?: LabelPosition;
    class?: string;
    value?: any;
    title?: string;
    placeholder?: string;
    id: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    isEditMode?: boolean;
    validateFn?: (value: any) => string;
};

export type TextInputConfig = BaseInput & {
    type: InputType.TEXT;
    password?: boolean;
    maxLength?: number;
    minLength?: number;
    pattern?: string | RegExp;
    size?: number;
};

export type NumberInputConfig = BaseInput & {
    type: InputType.NUMBER;
    min?: number;
    max?: number;
    format?: string;
    decimals?: number;
    maxLength?: number;
};

export type TextareaInputConfig = BaseInput & {
    type: InputType.TEXTAREA;
    maxLength?: number;
    rows?: number;
};

export type BooleanInputConfig = BaseInput & {
    type: InputType.BOOLEAN;
    nullable?: boolean;
};

export type DateInputConfig = BaseInput & {
    type: InputType.DATE;
    format: string;
    disabledDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
};

export type DateTimeInputConfig = BaseInput & {
    type: InputType.DATETIME;
    format: string;
    disabledDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
};

export type DateRangeInputConfig = BaseInput & {
    type: InputType.DATERANGE;
    format: string;
    minDate?: Date;
    maxDate?: Date;
};

export type FileInputConfig = BaseInput & {
    type: InputType.FILE;
    isMultiple?: boolean;
    showFileList?: boolean;
    restrictions: FileRestrictions;
};

export type SelectInputConfig = BaseInput & {
    type: InputType.SELECT;
    options: { value: string; label: string; [key: string]: any }[];
    defaultItem?: { value: string; label: string; [key: string]: any };
    customFilter?: (item: any, query: string) => boolean;
    isMultiple?: boolean;
    isFilterable?: boolean;
    isClearable?: boolean;
    textField?: string;
    valueField?: string;
    popupSettings?: Partial<PopupSettings>;
    valuePrimitive?: boolean;
};

export type CodelistInputConfig = BaseInput & {
    type: InputType.CODELIST;
    codelist: Codelists | FakedCodelists;
    isMultiple?: boolean;
    isClearable?: boolean;
    textField?: string;
    valueField?: string;
    popupSettings?: Partial<PopupSettings>;
    omittedCodelistIds?: string[] | number[];
    formatLabel?: (item: CodeList) => string;
    customFilter?: (item: CodeList, query: string) => boolean;
};

export type EditorInputConfig = BaseInput & {
    type: InputType.EDITOR;
};

export type InputConfig =
    | TextInputConfig
    | NumberInputConfig
    | TextareaInputConfig
    | BooleanInputConfig
    | DateInputConfig
    | DateTimeInputConfig
    | DateRangeInputConfig
    | FileInputConfig
    | SelectInputConfig
    | CodelistInputConfig
    | EditorInputConfig;
